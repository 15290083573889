<template>
  <div
    class="add-new-mapping-setting w-100 h-100"
    @click="enableEditModeAndStop($event)"
  >
    <div v-if="mode == ADD_MODE" class="d-flex align-center w-100 h-100 pl-4">
      {{ $localizationService.localize("drp_import.stepper.mapping.new_btn") }}
    </div>
    <div v-else class="d-flex align-center mapping-padding w-100 h-100">
      <div
        v-if="mode == EDIT_MODE"
        class="w-100 h-100 d-flex align-center mapping-in-select-padding pr-2"
        @click.stop=""
      >
        <v-text-field
          @click="disableActivatedFocus()"
          v-model="modelMapping.name"
          autofocus
          :label="
            $localizationService.localize(
              'drp_import.stepper.mapping.param.name'
            )
          "
        ></v-text-field>

        <v-icon
          class="ml-2"
          color="green"
          @click.stop="saveModelMapping()"
          :disabled="!modelMapping.name"
          >mdi-check</v-icon
        >
        <v-icon class="ml-2" color="red" @click.stop="close()"
          >mdi-close</v-icon
        >
      </div>

      <div v-if="mode == VIEW_MODE" class="mapping-in-select-padding">
        <span>{{ modelMapping.name }}</span>
      </div>

      <v-spacer></v-spacer>

      <v-btn
        v-if="mode == VIEW_MODE"
        class="ml-2"
        icon
        @click.stop="enableEditMode()"
        :disabled="pLoading"
      >
        <v-icon> mdi-pencil </v-icon>
      </v-btn>
      <v-btn
        v-if="modelMapping && modelMapping.id"
        icon
        color="red"
        @click.stop="openDeleteModelMappingDialog(modelMapping)"
        :disabled="pLoading"
      >
        <v-icon> mdi-delete </v-icon>
      </v-btn>
    </div>
    <ConfirmationDialog ref="confirmationDialog" />
  </div>
</template>

<script>
import Vue from "vue";

const locService = Vue.prototype.$localizationService;

const ADD_MODE = "add";
const EDIT_MODE = "edit";
const VIEW_MODE = "view";

export default {
  name: "ModelMappingComponent",

  components: {
    ConfirmationDialog: () => import("../../ConfirmationDialog.vue"),
  },

  props: {
    pModelMapping: Object,
    pLoading: Boolean,
  },

  watch: {
    pModelMapping() {
      this.modelMapping = this.pModelMapping;
    },
  },

  data() {
    return {
      ADD_MODE,
      EDIT_MODE,
      VIEW_MODE,

      mode: undefined,

      modelMapping: this.pModelMapping ? { ...this.pModelMapping } : undefined,
    };
  },

  methods: {
    enableEditModeAndStop(event) {
      if (this.mode == ADD_MODE) {
        this.enableEditMode();
        event.stopPropagation();
      }
    },

    enableEditMode() {
      if (this.mode == VIEW_MODE) {
        this.disableActivatedFocus();
        this.mode = EDIT_MODE;
        return;
      }

      if (this.mode == ADD_MODE) {
        this.modelMapping = {
          name: "",
        };
        this.mode = EDIT_MODE;
      }
    },

    saveModelMapping() {
      this.$emit("onSaveModelMapping", { ...this.modelMapping });
      this.closeEditMode();
    },

    close() {
      if (this.pModelMapping) {
        this.modelMapping.name = this.pModelMapping.name;
      }
      this.closeEditMode();
    },

    closeEditMode() {
      this.disableActivatedFocus();

      if (this.modelMapping && this.modelMapping.id) {
        this.mode = VIEW_MODE;
      } else {
        this.mode = ADD_MODE;
        this.modelMapping.name = "";
      }
    },

    disableActivatedFocus() {
      this.$emit("onDeactivateFocus");
    },

    openDeleteModelMappingDialog(modelMapping) {
      this.disableActivatedFocus();
      this.$refs.confirmationDialog.openDialog({
        header: locService.localize(
          "drp_import.stepper.mapping.dialog.delete.title",
        ),
        text: locService.localize(
          "drp_import.stepper.template.dialog.delete.text",
          [modelMapping.name],
        ),
        submitText: locService.localize("btn.delete"),
        submitColor: "red",
        onSubmit: async () => this.$emit("onDeleteModelMapping", modelMapping),
      });
    },
  },

  mounted() {
    if (this.modelMapping) {
      this.mode = VIEW_MODE;
    } else {
      this.mode = ADD_MODE;
    }
  },
};
</script>

<style lang="scss">
.mapping-settings {
  .mapping-padding {
    padding-right: 3.5em;
  }
  .v-input__append-inner {
    z-index: 999999 !important;
  }
}
.v-list-item {
  .mapping-in-select-padding {
    padding-left: 1em;
  }
}
.add-new-mapping-setting {
  position: absolute !important;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999999 !important;
  .v-label--active {
    display: none !important;
  }
  .v-input {
    padding: 0;
    margin: 0;
    .v-input__slot {
      margin: 0;
    }
  }
  .v-text-field__details {
    display: none !important;
  }
}
</style>
